.panelHeaderCircle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    padding: 5px;
    background: #7894bc;
    text-align: center;
}

.collapseHeader {
    background: #184c8c !important;
    margin-top: 25px !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.flexRowR {
    display: flex;
    flex-direction: row-reverse;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.totalLine {
    display: flex;
    justify-content: end;
    padding-top: 10px;
    padding-right: 16px;
}