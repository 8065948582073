.headerActionBar {
    width: 100%;
    background-color: var(--color-secondary);
    padding: 6px 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}


.headerActionBarLeft,
.headerActionBarRight {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(max-content, 0.3fr);
    column-gap: 1rem;
    row-gap: 1rem;
}

.headerActionBarRight {
    direction: rtl;
    grid-auto-columns: minmax(max-content, 0.3fr);
}

.toggleSwitch {
    margin: 10px;
    align-self: center;
}

.actionBarButton {
    width: 100%;
    background: var(--color-media) !important;
    border-color: var(--color-media) !important;
}

.SpecialActionBarButton {
    width: 100%;
    align-self: center;
    background: var(--color-media) !important;
    border-color: var(--color-media) !important;
}

.wrapper { display: flex; }

@media (max-width: 376px) {
    .headerActionBar {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center !important;
    }

    .headerActionBarLeft,
    .headerActionBarRight {
        margin: auto;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
}