.loginFormWrapper {
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.loginForm {
    width: 100%;
}

.loginFormContent {
    padding: 0;
    margin: 0;
    max-width: 90%;
}

/* .loginFormContent h2 {
    text-align: right;
} */

.loginButton {
    float: right;
}

@media (min-width: 600px) {
    .loginFormContent {
        width: 600px;
    }
}

.validationResults {
    color: #F52265;
}

.loginTitle {
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 60px;
}
