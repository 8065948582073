.loadingIndicator {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    display: grid;
    justify-content: center;
    align-content: center;
}
