.page-wrapper {
    width: 100%;
    height: var(--app-height);
    display: grid;
    grid-template-rows: 1fr auto;
}

.page-wrapper.with-header {
    height: var(--app-height);
    grid-template-rows: auto 1fr auto;
}

@media (max-width: 700px) {
    .custom-ps-wrapper {
        padding: 16px 12px;
    }
}

.wrapperContent{
    height: 100%;
}