.viewLogin {
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 100;
    padding: 0;
    grid-template-rows: auto 3fr 1fr;
}


.viewLoginHeader {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    background-color: var(--color-primary);
    padding: 5px;
}

.appLogo {
    max-width: 90%;
}
