.footerButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.buttonLeft{
    align-items: left;
}

.buttonRight{
    float: right;
}

.tableWidth {
    width: auto;
    min-width: unset !important;
  }
