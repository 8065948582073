
.nestedRow {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 70px;
    margin-right: 70px;
    border-color: black;
}


@media  (max-width: 450px) {
    .nestedRow {
        margin-left: 30px !important;
    }
}