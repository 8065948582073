.homePageView {
    width: 100%;
    height: auto;
}

.content {
    width: 100%;
    height: 100%;
}

.homeButton {
    width: 100%;
    height: 80px !important;
}
    