.header{
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    justify-content:center;
}

@media  (max-width: 450px) {
    .header{
        flex-direction: column;
        align-items: flex-end;
    }
    .filter{
        margin: auto !important;
        margin-left: 30px !important;
    }
    .search{
        margin: auto !important;
        margin-left: 30px !important;
    }
    .exportButton{
        margin: auto !important;
        margin-left: 30px !important;
    }
    
}

.filter {
    padding-top: 20px;

    margin: auto;
    margin-left: 70px;

}

.search {
    margin-right: 20px;
    padding-top: 20px;
    height: 50px;
}

.exportButton{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 70px;
    padding-top: 20px;
    align-self: right;
}

