.articleFormCard{
    box-shadow: 0px 0px 2px;
    background-color: #FBFBFB !important;
    border-left: 6px solid #7795b9;
}
.articleFormCard>:global(.ant-card-head){
    min-height: 10px;
}
.articleFormCard :global(.ant-card-head-title){
    padding:5px;
}
