.headerContent {
    display: grid;
    grid-template-columns: auto 1fr 40px;
    padding: 1.5rem;
    background-color: var(--color-primary);
}

.headerLogo {
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.pageTitle {
    padding: 0px 20px;
    align-self: center;
    margin: 0;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 16px;
}


@media (min-width: 450px) {
    .pageTitle {
        font-size: 21px;
    }
}
