.menuCloseButton {
    position: absolute;
    top: 22px;
    right: 10px;
}

.menuUserInfo {
    width: 100%;
    display: grid;

    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    min-height: 76px;
}


.menuInfoWrapper {
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100%;
    display: grid;

    justify-items: center;
    justify-content: center;
}
