.articleForm {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
}
.costPrice {
    width: 100%;
}

.salesPrice {
    width: 100%;
}
.saveFirstMessage {
    padding: 20px
}
@media (min-width: 800px) {
    .largeColumnWidth {
        grid-column: span 2;
    }
    .largeColumnHeight {
        grid-row: span 2;
    }
    .articleForm {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1200px) {
    .largeColumnWidth {
        grid-column: span 2;
    }
    .largeColumnHeight {
        grid-row: span 2;
    }
    .articleForm {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.numberInput {
    width: 100% !important;
}
.stockEditButton {
    width: 100%;
}
