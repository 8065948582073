.backorderBlock {
    width: 100%;
    min-height: 200px;

    background-color: #fbfbfb;

    padding: 1rem;

    display: grid;
    grid-template-columns: 1fr 2fr;

    column-gap: 1rem;
    row-gap: 1rem;
    box-shadow: 0px 0px 2px;
    border-left: 6px solid var(--color-secondary);
    border-radius: 5px;
}

.backorderInfo {
    position: relative;
    padding-bottom: 40px;
    user-select: text;
}


.rightBottomSpace {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
}

.backorderImageSpace {
    cursor: pointer;
}
.backorderName {
    font-weight: bold;
    font-size: 16px;
    cursor:pointer;
}

.backorderName:hover {
    text-decoration: underline;
}


.infoGrid > div {
    display: grid;
    /* font-family: cursive; */
    grid-template-columns: 1fr 1fr;
    width: 80%;
    font-size: 13px;
    border-bottom: 1px solid #eee;
}

.infoGrid>div>div:nth-child(2) {
    justify-self: right;
    align-self: center;
}

.flexItems {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    color: white;
    font-weight: bold;
    width: 100%;
}

.orderNumber{
    font-weight: bold;
}

.datetime{
    margin-left: 20px;
    flex-grow: 0.8;

}

.paddingCount2 {
    margin-right: 0px;
}

.arrow{
    margin-left: 10px;
    align-self: flex-end;
}
