.articleList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.articleList.useList {    
    grid-template-columns: 1fr !important;    
}

 @media (min-width: 800px) {
    .articleList {
        grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 24px 24px 0px 24px;
    }    
}

@media (min-width: 1200px) {
    .articleList {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 24px 24px 0px 24px;
        background-color: #ffffff;
    }
} 

.paginationCover{
    margin-top: 20px;
    margin-left: 25px;
    padding-bottom: 20px;
}

.searchBar {
    position: sticky;
    width: 100%;
    height:64px;
    padding: 16px 24px 40px 24px;
    background-color: #7795B9;
    z-index: 100;
}
