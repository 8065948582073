.menuToggleButton {
    position: absolute;
    z-index: 15;
    right: 10px;
    top: 20px;
    padding: 2px 10px;
    font-size: 2rem;
    width: auto;
    height: auto;
    left: auto;
    color: white;
}

.menuToggleButton:hover,
.menuToggleButton:focus {
    filter: brightness(0.8);
}

.menuToggleButton:active {
    filter: brightness(0.7);
}

.menuWrapper {
    width: 100%;
    position: absolute;
    z-index: 30;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.menuContent {
    position: absolute;
    z-index: 30;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #555;
}

.menuCloseOverlay {
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}
