.footerButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.buttonLeft{
    align-items: left;
}

.buttonRight{
    float: right;
}

.tableWidth {
    width: auto;
    min-width: unset !important;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.3);    
}

.form {
    display:block;
    position: relative;
}