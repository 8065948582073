.articleView {
    width: 100%;
    height: 100%;
}

.content {
    position: relative;
    margin-bottom: 30px;
    padding: 16px 24px;
}
